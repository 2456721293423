<template>
  <v-row>
    <v-col cols="12">
      <v-skeleton-loader v-if="$store.state.accounts.retrieveLoader" transition="fade-transition" type="article" />
      <template v-else>
        <v-card>
          <v-toolbar color="transparent" flat height="55">
            <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Elementos de diseño</v-toolbar-title>
          </v-toolbar>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <span class="body-1 text--secondary font-weight-medium">Logo</span>
                <v-sheet class="mt-3">
                  <input ref="editable.logo" type="file" accept="image/png" style="display:none" @change="getLogoPreview">
                  <v-btn v-if="!logoPreview && !editable.logo" @click="$refs['editable.logo'].click()" outlined color="secondary"><v-icon>mdi-plus</v-icon></v-btn>
                  <v-hover v-if="logoPreview || editable.logo" v-slot:default="{hover}">
                    <v-img :loading="logoPreview" :aspect-ratio="4" contain class="rounded" :src="logoPreview ? logoPreview : editable.logo" @click="$refs['editable.logo'].click()" max-width="200" min-height="90" style="border:1px solid #D8E0EA;">
                      <v-expand-transition>
                        <div v-if="hover" class="d-flex transition-fast-in-fast-out background v-card--reveal primary--text font-weight-medium" style="cursor:pointer; height:100%;">Seleccionar</div>
                      </v-expand-transition>
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular size="30" indeterminate color="blue lighten-4" />
                        </v-row>
                      </template>
                    </v-img>
                  </v-hover>
                </v-sheet>
              </v-col>
              <v-col cols="4">
                <span class="body-1 text--secondary font-weight-medium">Color principal</span>
                <v-sheet class="mt-3" width="140">
                  <v-text-field v-model="$v.editable.color_background.$model" reverse outlined required single-line dense hide-details>
                    <template v-slot:append>
                      <v-menu v-model="menu" bottom :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div :style="swatchStyle" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="$v.editable.color_background.$model" flat width="250" />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-sheet>
              </v-col>
              <v-col cols="4">
                <span class="body-1 text--secondary font-weight-medium">Color secundario</span>
                <v-sheet class="mt-3" width="140">
                  <v-text-field v-model="$v.editable.color_asset.$model" reverse outlined required single-line dense hide-details>
                    <template v-slot:append>
                      <v-menu v-model="menu1" bottom :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div :style="swatchStyle1" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="$v.editable.color_asset.$model" flat width="250" />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-4">
            <v-spacer />
            <v-btn @click="patchAccount" :loading="loading" color="primary">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ReminderDocumentsEmail from '@/components/reminders/documents/Email'
export default {
  components: {
    ReminderDocumentsEmail
  },
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    menu: false,
    menu1: false,
    logoPreview: '',
    editable: {
      logo: '',
      color_background: '',
      color_asset: ''
    },
    loading: false
  }),
  computed: {
     swatchStyle () {
      return {
        backgroundColor: this.editable.color_background,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px'
      }
    },
    swatchStyle1 () {
      return {
        backgroundColor: this.editable.color_asset,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px'
      }
    },
     ...mapState({
      accountsDetail: state => state.accounts.accountsDetail
    })
  },
  created () {
    if (this.accountsDetail) {
      this.editable = {
        logo: this.accountsDetail.logo,
        color_background: this.accountsDetail.color_background ?? '#304FFE',
        color_asset: this.accountsDetail.color_asset ?? '#ECEDF6'
      }
    }
  },
  methods: {
    patchAccount () {
      this.editable.logo = this.$refs['editable.logo'].files[0] ?? this.accountsDetail.logo
      this.$v.editable.$touch()
      if (this.$v.editable.$invalid) {
        return false
      }
      this.loading = true
      const payload = new FormData()
      if (this.editable.logo) {
        payload.append('logo', this.editable.logo, this.editable.logo.name)
      }
      payload.append('color_background', this.editable.color_background)
      payload.append('color_asset', this.editable.color_asset)
      this.$store.dispatch('accounts/UPDATE', {
        resource: 'accounts',
        payload: payload,
        id: this.accountsDetail.id,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        this.$store.commit('auth/SET_ACCOUNT', { account: response.data, redirect: false })
        this.editable = {
          logo: response.data.logo,
          color_background: response.data.color_background,
          color_asset: response.data.color_asset
        }
        this.$dialog.message.info('La información de la organización se ha actualizado')
        this.resetLogoField()
        this.loading = false
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
    },
    getLogoPreview (e) {
      if (e.target.files.length) {
        this.logoPreview = URL.createObjectURL(e.target.files[0])
      } else {
        this.logoPreview = ''
      }
    },
    resetLogoField () {
      this.$refs['editable.logo'].type = 'text'
      this.$refs['editable.logo'].type = 'file'
      this.logoPreview = ''
    }
  },
  validations: {
    editable: {
      color_background: {
        required,
        minLength: minLength(7),
        maxLength: maxLength(7)
      },
      color_asset: {
        required,
        minLength: minLength(7),
        maxLength: maxLength(7)
      }
    }
  }
}
</script>
<style scoped>
::v-deep .v-text-field .v-input__append-inner {
  padding-left: 3px !important;
  margin-top: 3px !important;
  margin-bottom: 2px !important;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}
</style>